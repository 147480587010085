import ConfirmationCart from "./order/ConfirmationCart";
import ConfirmOrder from "./order/ConfirmOrder";
import ConfirmPayment from "./order/ConfirmPayment";

export default {
  install(Vue /*, options */) {
    Vue.component("confirmation-cart", ConfirmationCart);
    Vue.component("confirm-order", ConfirmOrder);
    Vue.component("confirm-payment", ConfirmPayment);

    Vue.filter("onlyDate", dateString => {
      if (!dateString) {
        return "";
      }
      return dateString.substring(0, 10);
    });
  }
};
