<template>
  <div id="app" :scroll="handleScroll">
    <main class="flex flex--expand flex--column">
      <router-view />
    </main>
    <transition name="fade">
      <div class="back-to-top" v-if="showBackToTopButton" title="Back to top">
        <img src="/img/back-top.png" alt @click="backToTop" />
      </div>
    </transition>
  </div>
</template>
<style>
.font-weight-bold {
  font-weight: 500 !important;
}

.row2 {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 40px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.first-section {
  width: 95%;
  margin: 30px auto;
  background-color: #f8f9fa; /* #00aaff; */
  border-radius: 3px;
}

.first-section h1 {
  text-align: center;
  color: #fff;
  letter-spacing: 1px;
  padding: 10px;
  font-size: 23px;
}

.container2 {
  box-sizing: border-box;
  box-shadow: 10px 10px rgba(0, 0, 51, 0.09);
  width: 150px;
  height: 80px;
  margin: 20px 0px 40px;
  background-color: #fff;
  border-radius: 2px;
}

.container2 p {
  text-align: center;
  padding: 10px;
  font-size: 15px;
  color: #1a1a1a;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.arrow {
  width: 120px;
}

.line {
  width: 105px;
  height: 2px;
  background: #000;
  float: left;
  margin-top: 58px;
}
.point {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #000;
  float: right;
  margin-top: 50px;
}
.approvedSteps {
  background-color: green !important;
  color: white !important;
}
.unapprovedSteps {
  background-color: red !important;
  color: white !important;
}
</style>
<script>
export default {
  data() {
    return {
      showBackToTopButton: false
    };
  },
  mounted() {
    window.document.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.showBackToTopButton = true;
      } else {
        this.showBackToTopButton = false;
      }
    },
    backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>
