<template name="component-name">
  <table class="car-options mt-4">
    <tr>
      <th style="width:24%">Payment Mode</th>
      <td>:</td>
      <td style="width:74%">
        <select
          class="mr-sm-2 form-control"
          v-model="selectedorder.PaymentMode"
        >
          <option></option>
          <option value="Deposit">My available deposit</option>
          <option value="Transfer">Bank Transfer</option>
          <option value="Deposit">My available credit</option>
          <!-- <option value="Cheque">Cheque</option>
          <option value="Draft">Draft</option>-->
        </select>
      </td>
    </tr>
    <tr>
      <th>Payment Details (e.g Transfer Reference)</th>
      <td>:</td>
      <td>
        <textarea
          class="mr-sm-2 form-control"
          rows="3"
          v-model="selectedorder.PaymentDetails"
        ></textarea>
      </td>
    </tr>

    <tr>
      <th>Additional info</th>
      <td>:</td>
      <td>
        <textarea
          class="mr-sm-2 form-control"
          rows="3"
          v-model="selectedorder.AdditionalPaymentInstruction"
        ></textarea>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      discountAmount: 0
    };
  },
  props: {
    selectedorder: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
