<template name="component-name">
  <div class="confirmation">
    <h2>Verify the items you added</h2>

    <table class="table b-table cart-table table-striped table-hover">
      <thead>
        <tr>
          <!-- <th>Image</th> -->
          <th>Item</th>
          <th>Color</th>
          <th>Unit Price</th>
          <th>Requested</th>
          <!--<th>Available</th>-->
          <th>Total Price</th>
          <th v-if="discountAllowed">Unit Discount Requested</th>
          <th v-if="discountAllowed">Total Discount Requested</th>
        </tr>
      </thead>
      <tbody>
        <tr role="row" v-for="(item, i) in cart" :key="i">
          <!-- <td>
            <img
              :src="item.thumbnail"
              alt="thumbnail"
              class="confirm-cart-image"
            />
          </td>-->
          <td>{{ item.Description }}</td>
          <td>{{ item.selectedColor }}</td>
          <td>{{ item.CategoryA | amount }}</td>
          <td>{{ item.selectedUnit }}</td>
          <!--
          <td>
            {{ item.Available }}
          </td>
          -->
          <td style="text-align: right">
            {{ (parseInt(item.selectedUnit) * item.CategoryA) | amount }}
          </td>
          <td v-if="discountAllowed">
            <b-form-input
              type="number"
              size="sm"
              class="discount-amount"
              v-model="item.DiscountRequested"
            >
            </b-form-input>
          </td>
          <td v-if="discountAllowed">
            {{
              (parseFloat(item.DiscountRequested) *
                parseFloat(item.selectedUnit))
                | amount
            }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <!-- <th colspan="3"></th> -->
          <th></th>
          <th></th>
          <th>
            <!-- {{ (parseFloat(totalPrice) / parseFloat(totalAvailable)) | amount }} -->
          </th>
          <th>{{ totalUnit }}</th>
          <!-- <th>{{ totalAvailable }}</th> -->
          <!--
          <th>
            {{
              cart
                .map(e => {
                  return e.Available;
                })
                .reduce((x, y) => x + y)
            }}
          </th>
          -->
          <th style="text-align: right">{{ totalPrice | amount }}</th>
          <th v-if="discountAllowed" colspan="2" style="text-align: right">
            Total Discount requested: {{ totalDiscount | amount }}
          </th>
        </tr>
        <tr>
          <th colspan="4" style="text-align: right">VAT @ 7.5%:</th>
          <th style="text-align: right">
            {{ Math.round((parseFloat(totalPrice) * 7.5) / 100) | amount }}
          </th>
          <th v-if="discountAllowed"></th>
          <th v-if="discountAllowed"></th>
        </tr>
        <tr>
          <th colspan="4" style="text-align: right">
            <h4>Grand Total:</h4>
          </th>
          <th style="text-align: right">
            <h4>
              {{
                Math.round(
                  parseFloat(totalPrice) + (parseFloat(totalPrice) * 7.5) / 100
                ) | amount
              }}
            </h4>
          </th>
          <th v-if="discountAllowed"></th>
          <th v-if="discountAllowed"></th>
        </tr>
      </tfoot>
      <!---->
    </table>
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      discountAmount: 0,
      totalDiscount: 0
    };
  },
  props: {
    cart: {
      type: Array,
      default: () => []
    },
    totalUnit: {
      type: Number,
      default: 0
    },
    totalAvailable: {
      type: Number,
      default: 0
    },
    totalPrice: {
      type: Number,
      default: 0
    },
    discountAllowed: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    amount: function(value) {
      const { format } = require("number-currency-format");
      value = format(value, {
        currency: "=N=",
        spacing: false,
        currencyPosition: "LEFT"
      });
      return value;
    }
  },
  methods: {
    calculatevalue(item) {
      return parseFloat(item.DiscountRequested) * parseFloat(item.selectedUnit);
    },
    formatNumber(n, p, ts, dp) {
      var t = [];
      // Get arguments, set defaults
      if (typeof p == "undefined") p = 2;
      if (typeof ts == "undefined") ts = ",";
      if (typeof dp == "undefined") dp = ".";

      // Get number and decimal part of n
      n = Number(n)
        .toFixed(p)
        .split(".");

      // Add thousands separator and decimal point (if requied):
      for (
        var iLen = n[0].length, i = iLen ? iLen % 3 || 3 : 0, j = 0;
        i <= iLen;
        i += 3
      ) {
        t.push(n[0].substring(j, i));
        j = i;
      }
      // Insert separators and return result
      return t.join(ts) + (n[1] ? dp + n[1] : "");
    }
  },
  watch: {
    cart: {
      handler: function(val) {
        let that = this;
        that.totalDiscount = val.reduce(function(accumulator, item) {
          let data = item.DiscountRequested.replaceAll(",", "");
          return accumulator + parseFloat(data) * item.selectedUnit;
        }, 0);
        console.log("a thing changed from: ", that.totalDiscount);
      },
      deep: true
    }
  }
};
</script>
<style>
.modal-xl {
  min-width: 90% !important;
}
</style>
