<template name="component-name">
  <div>
    <table class="car-options mt-4">
      <!-- <tr>
                <th>Order Number</th>
                <td>:</td>
                <td>{{ clickedCoupon.OrderNumber }}</td>
      </tr>-->
      <tr>
        <th>Order Date</th>
        <td>:</td>
        <td>{{ clickedCoupon.OrderDate | formatDate }}</td>
      </tr>
      <tr>
        <th>Total Amount</th>
        <td>:</td>
        <td>{{ clickedCoupon.OrderTotal | amount }}</td>
      </tr>
    </table>

    <br />

    <table
      class="table b-table cart-table table-striped table-bordered table-hover"
    >
      <thead>
        <tr>
          <th>Item</th>
          <th>Colour</th>
          <th>Unit(s)</th>
        </tr>
      </thead>
      <tbody>
        <tr role="row" v-for="(item, i) in clickedCoupon.orderDetails" :key="i">
          <td>{{ item.VehicleName }}</td>
          <td>{{ item.Colour }}</td>
          <td>{{ item.OrderQty }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>Total</th>
          <th>{{ totalUnit }}</th>
          <th colspan="2">{{ clickedCoupon.OrderTotal | amount }}</th>
        </tr>
      </tfoot>
      <!---->
    </table>
  </div>
</template>

<script>
export default {
  computed: {
    totalUnit() {
      let totalUnit = this.clickedCoupon.orderDetails.reduce(
        (accumulator, currentValue) => {
          return parseInt(currentValue.OrderQty) + accumulator;
        },
        0
      );

      return totalUnit;
    },
  },
  data() {
    return {
      discountAmount: 0,
    };
  },
  props: {
    clickedCoupon: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
