/* eslint-disable no-undef */ /* eslint-disable no-undef */ /* eslint-disable
no-undef */ /* eslint-disable no-undef */ /* eslint-disable */
<template>
  <div class="home">
    <b-container
      fluid
      style="
        min-height: 100vh;
        min-width: 100vw;
        display: flex;
        place-items: center;
      "
      v-if="loadingVehicles"
    >
      <br />
      <b-spinner label="Loading..." style="margin: 0 auto"></b-spinner>
    </b-container>

    <b-container style="margin-top: 6rem" fluid v-if="!loadingVehicles">
      <h3 class="py-2">
        <span class="text-capitalize">New Vehicle Order</span>
      </h3>

      <hr />
      <b-alert show variant="danger" v-if="!canOrder" class="warningstyle" 
        >&nbsp; {{ orderWarning }} &nbsp;<br>
        <span v-if="dealerid == 3 && negativeBalance==true">Note that you have also overshot your vehicle credit limit by 
        <span class="text-danger">{{ (customerBalance*-1) | amount }}</span> and will not be able to use this option for payment.
        </span>
        </b-alert
      >
      <br />
      <b-row>
        <b-col md="8">
          <!-- <b-form inline>
            <b-form-input class="mr-sm-2 ml-3 col-md-4" placeholder="Filter" v-model="filterText"></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0 col-md-2"
              v-if="filterText"
              @click="filterText = ''"
            >Clear</b-button>

            <vue-infinite-autocomplete
              class="mr-sm-2 ml-3 col-md-4"
              :data-source="allVehcilesNoFilter"
              :value="filterText"
              v-on:select="handleOnSelect"
            ></vue-infinite-autocomplete>
          </b-form>-->
          <div class="row">
            <div class="col-md-8">
              <vue-typeahead-bootstrap
                class="mb-4"
                v-model="filterText"
                :data="allVehcilesNoFilter"
                :serializer="(item) => item.Description"
                @keydown.enter="search"
                placeholder="Search Available Models"
                prepend="Model Name:"
                @hit="handleClick($event)"
              >
                <template slot="append">
                  <button class="btn btn-primary">Search</button>
                </template>
              </vue-typeahead-bootstrap>
            </div>

            <b-alert dismissible show variant="danger" class="col-md-4"
              >Note that all prices are VAT exclusive</b-alert
            >
          </div>

          <div class="products mt-4">
            <VehicleCard
              v-for="(car, i) in allVehciles"
              :key="i"
              :car="car"
              @selected="handleClick(car)"
              :isCategoryA="isCategoryA"
            />
            <!-- <b-card :img-src="car.thumbnail" img-alt="Thumbnail" img-top tag="article" class="product mb-4"
              v-for="(car, i) in allVehciles" :key="i" @click="handleClick(car)">
              <p class="hover-name">{{ car.Description }}</p>
              <b-card-text>
                <strong>Item No.:</strong>
                {{ car.Item }}
                <br />
                <strong>Price:</strong>
                {{ (isCategoryA ? car.CategoryA : car.CategoryB) | amount }} /
                unit
                <br />
                <strong>Total Units:</strong>
                {{ car.Available }}
                <br />
                <br />

                <b-list-group style="max-width: 300px">
                <div v-if="car.Source !== 'DealerSpecific'">
                  <b-list-group-item style="padding: 0.35rem 1rem" class="d-flex align-items-center"
                    v-for="(item, i) in car.ColorsAndQuantity"  :key="i">
                    <span class="mr-auto">{{ item.Colour }}</span>
                    <h6>
                      <b-badge variant="danger">{{ item.Quantity }}</b-badge>
                    </h6>
                  </b-list-group-item>
                  </div>
                  <div v-else>
                   <b-list-group-item style="padding: 0.35rem 1rem" class="d-flex align-items-center"
                    v-for="(item, i) in car.AvailableColors"  :key="i">
                    <span class="mr-auto">{{ item }}</span>
                  </b-list-group-item>
                  </div>
                  
                </b-list-group>
              </b-card-text>
            </b-card> -->
          </div>
        </b-col>
        <b-col md="4">
          <div class="my-cart mb-4">
            <h2 style="text-align: center">Cart</h2>
            <template v-if="cart.length > 0">
              <table class="table b-table cart-table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Unit</th>
                    <th colspan="2">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" v-for="(item, i) in cart" :key="i">
                    <td>
                      {{ item.Description }}
                      <br />
                      <span class="item-color">({{ item.selectedColor }})</span>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.selectedUnit"
                        :max="item.maxQuantity"
                        type="number"
                        size="sm"
                        @keyup="enforceMaxQuantity(item)"
                        class="car-unit"
                      >
                      </b-form-input>
                    </td>
                    <td>
                      {{
                        (parseInt(item.selectedUnit) * item.CategoryA) | amount
                      }}
                    </td>
                    <td>
                      <span class="remove" @click="removeItem(i)">&times;</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <th>{{ totalUnit }}</th>
                    <th colspan="2">{{ totalPriceUsingOrder | amount }}</th>
                  </tr>
                </tfoot>
                <!---->
              </table>
              <p class="text-right">
                <b-button variant="danger" @click="placeOrder"
                  >Place Order</b-button
                >
                <br />
                <b-alert
                  show
                  variant="danger"
                  class="mt-2 text-left warningstyle"
                  v-if="!canOrder"
                  style="font-weight:bold !important"
                  >{{ orderWarning }}</b-alert
                >
              </p>
            </template>
            <template v-else>
              <div
                style="
                  height: 300px;
                  width: 140px;
                  display: flex;
                  place-items: center;
                  margin: 0 auto;
                "
              >
                <p class="text-center">Your cart is empty</p>
              </div>
            </template>
          </div>
          <!--
          <div class="my-cart mb-4">
            <h3>Current Balances</h3>

            <template v-if="balances.length > 0">
              <table class="table b-table cart-table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Limit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" v-for="(item, i) in balances" :key="i">
                    <td>
                      {{ item.AccountName }}
                    </td>
                    <td>
                      {{ item.Limit | amount }}
                    </td>
                    <td>
                      {{ item.Balance | amount }}
                    </td>
                  </tr>
                </tbody>
                
              </table>
               <p class="text-right">
                <b-button variant="danger" @click="placeOrder"
                  >Place Order</b-button
                >
              </p> 
            </template>
            <template v-else>
  <p class="text-center"></p>
</template>
          </div>
          -->
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      v-model="detailsModal"
      size="md"
      title="Car Details"
      hideFooter
      centered
      class="details-modal"
      id="details-modal"
    >
      <img :src="selectedCar.thumbnail" alt="Thumbnail" />
      <!-- <h4 class="mt-1">{{ selectedCar.name }}</h4> -->
      <p class="hover-name mt-4">{{ selectedCar.Description }}</p>

      <table class="car-options mt-2">
        <tr>
          <th>Item No.:</th>
          <td>{{ selectedCar.Item }}</td>
        </tr>
        <tr>
          <th>Price:</th>
          <td>
            {{
              (isCategoryA ? selectedCar.CategoryA : selectedCar.CategoryB)
                | amount
            }}
            / unit
          </td>
        </tr>
        <tr>
          <th>Color:</th>
          <td>
            <b-form-select
              v-model="selectedCar.selectedColor"
              :options="
                selectedCar.Source !== 'DealerSpecific' &&
                selectedCar.ColorsAndQuantity
                  ? selectedCar.ColorsAndQuantity.map((e) => {
                      return { value: e.text, text: e.text };
                    })
                  : selectedCar.Source === 'DealerSpecific' &&
                    selectedCar.UniqueAvailableColors
                  ? selectedCar.UniqueAvailableColors.map((e) => {
                      return { value: e, text: e };
                    })
                  : []
              "
            ></b-form-select>
          </td>
        </tr>
        <tr>
          <th>Unit:</th>
          <td>
            <b-form-input
              v-model="selectedCar.selectedUnit"
              type="number"
              placeholder="Unit"
              :max="selectedCar.totalUnits"
            ></b-form-input>
          </td>
        </tr>
      </table>

      <p class="text-right">
        <b-button variant="danger" class="mt-2" @click="addToCart"
          >Add To Cart</b-button
        >
      </p>
    </b-modal>

    <b-modal
      v-model="confirmModal"
      size="xl"
      title="Order Confirmation"
      :hideFooter="canOrder"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      centered
    >
      <b-alert
        :show="dismissCountDownFail || errorMessage"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChangedFail"
      >
        <p>{{ errorMessage }}</p>
        <b-progress
          variant="danger"
          :max="dismissSecs"
          :value="dismissCountDownFail"
          height="4px"
        ></b-progress>
      </b-alert>
      <confirmation-cart
        :cart="cart"
        :totalUnit="totalUnit"
        :totalPrice="totalPrice"
        :discountAllowed="discountAllowed"
        :totalAvailable="totalAvailable"
      ></confirmation-cart>
      <p class="text-left mt-2">
        <span class="text-danger">
          1. Note that your order will be reserved for 24hours after which if
          payment isn't received, it will be made available for other dealers.
        </span>
        <br />
        <span class="text-danger" v-if="!canOrder"
          >2. Because you have overdue invoices, {{ orderWarning }}</span
        >
        <br />
        <!-- <span class="text-danger" v-if="hasOutstandingItems">
          <span v-if="!canOrder">3.</span>
          <span v-else>2.</span>
          <span v-if="!placeSpecialOrder">
            &nbsp;
            <b-spinner
              small
              label="Small Spinner"
              type="grow"
              variant="danger"
            ></b-spinner
            >You have units that are not available for instant fulfillment in
            the color you have chosen. You can place special request for those
            below by checking the special request box below.
          </span>
          <span v-else>
            Items on Special request will be considered by the TNL marketing
            team and you will be notified via email to enable you proceed with
            the order.
          </span>
        </span> -->
      </p>

      <b-row cols="2" v-if="discountAllowed">
        <b-col>
          <label>Discount Reason:</label>
          <b-form-input
            placeholder="Discount Reason"
            v-model="DiscountReason"
          ></b-form-input>
        </b-col>
        <b-col>
          <label>Discount Supporting Document:</label>
          <b-form-file id="files" v-model="document" plain />
        </b-col>
      </b-row>

      <p class="text-right mt-2">
        <b-button
          variant="warning"
          class="mr-2"
          :pressed.sync="discountAllowed"
          :disabled="ordering"
        >
          <span v-if="discountAllowed">Cancel Discount</span>
          <span v-else>Request Discount</span>
        </b-button>

        <b-button
          variant="danger"
          class="mr-2"
          @click="confirmNow"
          :disabled="ordering"
        >
          <span v-if="ordering">Please wait</span>
          <span v-else-if="!ordering && discountAllowed"
            >Place Order with Discount</span
          >
          <span v-else>Place Order</span>
        </b-button>

        <!-- <b-form-checkbox
          class="mr-2"
          style="float: left; display: initial"
          v-if="hasOutstandingItems"
          v-model="placeSpecialOrder"
          >Make special request for outstanding items?</b-form-checkbox
        > -->
      </p>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left">{{ orderWarning }}</p>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="successModal"
      size="lg"
      title="Order Confirmation"
      centered
      :hide-footer="canOrder"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      <b-container class="confirmation">
        <h4 class="mt-1">
          Your order has been placed
          <span v-if="canOrder">successfully!</span>
        </h4>

        <p>
          Your order ID is
          <b>{{ orderid }}</b
          >.
          <span v-if="!orderWithDiscount">
            <br />
            <br />Your have until
            <b>{{ expiry }}</b>
            to make and confirm payment. Non payment by {{ expiry }}
            will invalidate the order and the vehicles will be made available to
            other dealers.
          </span>
        </p>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left">{{ orderNoteWarning }}</p>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="messageModal"
      size="lg"
      title="Order Not Allowed"
      hideFooter
      centered
    >
      <div class="error">
        <h4 class="mt-1">You are not elligible to place an Order,</h4>

        <p>
          Your need to clear some of your outstanding on the previous orders
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
//import VueInfiniteAutocomplete from "vue-infinite-autocomplete";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import VehicleCard from "../components/VehicleCard.vue";
import config from "../config";
import FileService from "../service/fileUpload";
import VehicleService from "../service/vehicle";
import { getData } from "./../utils/storage";

export default {
  components: {
    //"vue-infinite-autocomplete": VueInfiniteAutocomplete,
    "vue-typeahead-bootstrap": VueTypeaheadBootstrap,
    VehicleCard,
  },
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissCountDownFail: 0,
      balances: [],
      allVehciles: null,
      allVehcilesNoFilter: [],
      detailsModal: false,
      successModal: false,
      confirmModal: false,
      messageModal: false,
      errorMessage: "Discount Amount Cannot be null",
      selectedCar: { colors: [] },
      cart: [],
      filterText: "",
      loadingVehicles: true,
      ordering: false,
      orderid: "",
      expiry: null,
      document: null,
      dealerid: 0,
      dealerUserID: 0,
      discountAllowed: false,
      DiscountReason: "",
      DiscountProofURL: "",
      isCategoryA: false,
      canOrder: true,
      outstandingCart: [],
      hasOutstandingItems: false,
      placeSpecialOrder: false,
      footerBgVariant: "danger",
      footerTextVariant: "light",
      allocatedItems: [],
      orderWithDiscount: true,
      limitInfo: {},
      customerBalance: 0,
      negativeBalance: true,
      remainingLimit: 0,
      exceededLimit: false
    };
  },
  created() {
    //alert(this.orderWarning);
    //const myCars = axiosTNL.get("tdms/GetVehicleAvailability");
    /*allCars.sort(function(a, b) {
      return a.name.localeCompare(b.name);
    });
    */

    


    this.canOrder = getData("canOrder");
    const loggedInUser = getData("userInfo");
    this.dealerid = loggedInUser.DealerID;

    

    this.dealerUserID = loggedInUser.DealerUserID;
    this.isCategoryA = this.determineCategory(this.dealerid) == "A";
    this.getBalances();
    this.getAllVehicles();
    this.getDealerLimit(loggedInUser.DealerUserID);
  },
  computed: {
    availableColors(colours) {
      /*return []; */

      console.log("selected car:  ds  ", this.selectedCar);
      return colours.map((color) => {
        return { text: color, value: color };
      });
    },

    totalUnit() {
      let totalUnit = this.cart.reduce((accumulator, currentValue) => {
        return parseInt(currentValue.selectedUnit) + accumulator;
      }, 0);

      return totalUnit;
    },
    totalAvailable() {
      let totalAvailable = this.cart.reduce((accumulator, currentValue) => {
        return parseInt(currentValue.QuantityToSupply) + accumulator;
      }, 0);

      return totalAvailable;
    },

    totalPrice() {
      let totalUnit = this.cart.reduce((accumulator, currentValue) => {
        return (
          parseFloat(currentValue.selectedUnit) * currentValue.CategoryA +
          accumulator
        );
      }, 0);

      return totalUnit;
    },

    totalPriceUsingOrder() {
      let totalUnit = this.cart.reduce((accumulator, currentValue) => {
        return (
          parseFloat(currentValue.selectedUnit) * currentValue.CategoryA +
          accumulator
        );
      }, 0);

      return totalUnit;
    },
    eligibleToOrder() {
      //return this.balances.filter((x) => x.Balance > x.Limit).length > 0;
      return false;
    },
  },
  methods: {
    /*
    getDisplayPercent(itemcount) {
      if (itemcount <= 10) return itemcount;
      return Math.floor(itemcount * 0.6); //return sixty percent
    },
    uniqueColours: function (colours) {
      return [...new Set(colours)];
    },*/
    
    getDealerLimit: async function(id) {
      const { data } = await VehicleService.getCreditLimit(id);
      this.limitInfo = data;
      let limit = 0;
      if (data.Credit_Limit_LCY >= 2000000000) {
        limit = 1000000000;
      } else {
        limit = data.Credit_Limit_LCY;
      }
      this.customerBalance = limit - data.Balance;
      if (limit <= data.balance) this.negativeBalance = true;

      //return data;
    },
    enforceMaxQuantity(item) {
      if (item.maxQuantity < item.selectedUnit) {
        item.selectedUnit = item.maxQuantity;
      }
    },
    async getBalances() {
      VehicleService.getVehicleBalances(this.dealerid)
        .then((res) => {
          let { data } = res;
          this.balances = data.Data;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    async getAllVehicles() {
      this.loadingVehicles = true;
      console.log("DealerID: ", this.dealerUserID);
      let data = getData("accountIDs");
      if (!data) {
        console.log("No Dealer account ids");
        return;
      }
      //let _allocateditems = "";
      this.allocatedItems = [];

      // create a variable that joins an array of strings filtered to contain string TDV from variable data
      let accountIDs = data.filter((x) => x.includes("TDV")).join(",");
      // logout accountIds
      console.log("accountIDs: ", accountIDs);

      let allocation = [];
      try {
        let allocationinfo = await VehicleService.getAllocation(accountIDs);
        allocation = allocationinfo.data.data;
        console.log(allocationinfo)
        console.log("allocated items are: ", allocation);
      } catch (error) {
        console.log(error);
      }

      VehicleService.getVehicles(accountIDs)
        .then((res) => {
          let { data } = res;
          data.forEach((element) => {
            if (element.IsAllocated == 1) {
              console.log("Allocated vehicle: ", element);
              console.log("allocation variable is: ", allocation);
              //if (_allocateditems != "") _allocateditems += ",";
              //_allocateditems += element.Item;
try {
  if (allocation){
            let filter = allocation.filter((n) => n.Item == element.Item);
              console.log(element);
              if (filter.length == 0) {
                element.Avaiable = 0;
                element.AvailableColors = [];
                element.ColorsAndQuantity = [];
                element.Colours = "";
              } else {
                element.Avaiable = filter[0].Remaining_Quantity;
              }
            }
} catch (error) {
  alert(error)
}

              
              //this.allocatedItems.push(element.Item);
            }
          });
          // console.log(allocation);
          // this.allocatedItems.forEach((element) => {
          //   let filter = allocation.filter((n) => n.Item == element);
          //   console.log(element);
          //   if (filter.length == 0)
          //   {

          //   }
          //   console.log("filter returned", filter);
          // });

          //alert(_allocateditems);
          console.log(this.allocatedItems);
          /*
          data.forEach(element => {
            const displaySize = this.getDisplayPercent(element.Avaiable);
            element.AvailableColors = element.Colours.split(",").slice(
              0,
              displaySize
            );
            element.UniqueColors = this.uniqueColours(element.AvailableColors);
            element.Avaiable = displaySize;
            element.thumbnail = "/img/car-thumbnail.png";
          });*/
          /*
          this.allVehciles =
            this.determineCategory(this.dealerid) === "B"
              ? data.map((el) => {
                  return Object.assign({}, el, { CategoryA: el.CategoryB });
                })
              : data;

          this.filteredVehicleNames = data.map(function (item) {
            return item["Description"];
          });

          console.log(
            "data retuirned filteredVehicleNames is: ",
            this.filteredVehicleNames
          );*/
          this.allVehciles = data;
          this.allVehcilesNoFilter = data;
          console.dir(this.allVehciles);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loadingVehicles = false;
        });
    },
    handleClick(car) {
      this.selectedCar = { ...car };
      console.log("selected car: ", this.selectedCar);
      this.eligibleToOrder
        ? (this.messageModal = true)
        : (this.detailsModal = true);
    },
    handleSelect(vehicle) {
      console.log("selected vehicle from type ahead", vehicle);
      alert(vehicle);
    },
    addToCart() {
      // check already exist in cart or not
      let existId = this.cart.findIndex((item) => {
        return (
          item.Item === this.selectedCar.Item &&
          item.selectedColor === this.selectedCar.selectedColor
        );
      });
      console.log(this.selectedCar);
      if (
        this.selectedCar.selectedColor === "" ||
        !this.selectedCar.selectedUnit
      ) {
        return;
      }

      var matchingVehicle = this.allVehciles.filter(
        (n) => n.Item == this.selectedCar.Item
      )[0];

      var thecolor = matchingVehicle.AvailableColors.filter((n) =>
        n && this.selectedCar.selectedColor
          ? n === this.selectedCar.selectedColor
          : ""
      );
      var colors =
        matchingVehicle.Source !== "DealerSpecific"
          ? matchingVehicle.ColorsAndQuantity.filter((n) =>
              n.text && this.selectedCar.selectedColor
                ? n.text === this.selectedCar.selectedColor &&
                  matchingVehicle.Item == this.selectedCar.Item
                : ""
            )
          : matchingVehicle.UniqueAvailableColors.map((e) => {
              return { Quantity: 1, text: e };
            }).filter((n) =>
              n.text && this.selectedCar.selectedColor
                ? n.text === this.selectedCar.selectedColor &&
                  matchingVehicle.Item == this.selectedCar.Item
                : ""
            );

      //element.QuantityAvailable = thecolor.length;

      if (existId > -1) {
        let calculation =
          parseInt(this.cart[existId].selectedUnit) +
          parseInt(this.selectedCar.selectedUnit);
        this.cart[existId].selectedUnit =
          this.cart[existId].maxQuantity < calculation
            ? this.cart[existId].maxQuantity
            : calculation;

        this.cart[existId].QuantityToSupply =
          this.selectedCar.Source === "All"
            ? this.cart[existId].selectedUnit
            : parseInt(thecolor.length) <
              parseInt(this.cart[existId].selectedUnit)
            ? thecolor.length
            : this.cart[existId].selectedUnit;
        // parseInt(thecolor.length) < parseInt(this.cart[existId].selectedUnit)
        // ? thecolor.length
        // : this.cart[existId].selectedUnit;
      } else {
        if (!colors || this.selectedCar.selectedUnit > colors[0].Quantity) {
          return;
        } else {
          console.log("Selected Car: ", this.selectedCar);
          let itemToAdd = {
            id: this.selectedCar.id,
            Item: this.selectedCar.Item,
            Description: this.selectedCar.Description,
            thumbnail: this.selectedCar.thumbnail,
            CategoryA: this.isCategoryA
              ? this.selectedCar.CategoryA
              : this.selectedCar.CategoryB,
            selectedUnit: this.selectedCar.selectedUnit,
            selectedColor: this.selectedCar.selectedColor,
            UnitPrice: this.isCategoryA
              ? this.selectedCar.CategoryA
              : this.selectedCar.CategoryB,
            DiscountRequested: 0,
            maxQuantity: colors[0].Quantity,
            Available: this.selectedCar.Avaiable,
            from: this.selectedCar.Source,
          };
          itemToAdd["QuantityAvailable"] = thecolor.length;

          itemToAdd.IsAllocated = this.selectedCar.IsAllocated;
          itemToAdd.QuantityToSupply =
            this.selectedCar.Source === "All"
              ? itemToAdd.selectedUnit
              : parseInt(thecolor.length) < parseInt(itemToAdd.selectedUnit)
              ? thecolor.length
              : itemToAdd.selectedUnit;
          this.cart.push(itemToAdd);
        }
      }
      console.log("the item: ", this.cart);
      this.detailsModal = false;

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    removeItem(index) {
      this.cart.splice(index, 1);
    },
    placeOrder() {
      // this.cart = [];
      if (
        this.totalPriceUsingOrder <= 0 &&
        (!this.totalUnit || this.totalUnit <= 0)
      ) {
        return;
      }
      for (var e of this.cart) {
        if (e.selectedUnit === "" || e.selectedUnit === 0) {
          return;
        }
      }

      this.confirmModal = true;
      let that = this;
      that.outstandingCart = [];
      that.hasOutstandingItems = false;
      this.cart.forEach((element) => {
        var matchingVehicle = that.allVehciles.filter(
          (n) => n.Item == element.Item
        )[0];
        console.log("selected color is: ", element.selectedColor);
        /*var length = 0; */
        var thecolor = matchingVehicle.AvailableColors.filter((n) =>
          n && element.selectedColor
            ? n.trim() == element.selectedColor.trim()
            : ""
        );
        element.QuantityAvailable =
          element.from === "All" ? thecolor.length : null;
        if (parseInt(thecolor.length) < parseInt(element.selectedUnit)) {
          element.QuantityToSupply = thecolor.length;
          that.outstandingCart.push(element);
          that.hasOutstandingItems = true;
          //alert("dsjdjsjhds");
        } else {
          element.QuantityToSupply = element.selectedUnit;
        }
      });

      console.log("Final cart details: ", that.cart);
      console.log("Outstanding cart details: ", that.outstandingCart);
    },
    confirmNow: async function () {
      this.ordering = true;
      let uploadresponse = "";
      let orderItems = [];
      if (!this.validateDiscount()) {
        this.errorMessage = "Discount Amount Cannot be null";
        this.showFailureAlert();
        return;
      }
      this.cart.forEach((item) => {
        let row = {
          Item: item.Item,
          Colour: item?.selectedColor ?? "",
          Quantity: item.QuantityToSupply,
          Description: item.Description,
          UnitPrice: item.UnitPrice,
          QuantityRequested: item.selectedUnit,
          IsAllocated: item.IsAllocated,
        };
        if (this.discountAllowed) {
          item.DiscountRequested = item.DiscountRequested || 0;
          row.DiscountRequested = item.DiscountRequested;
        }
        orderItems.push(row);
      });

      const order = {
        UserId: this.dealerUserID,
        OrderInfo: orderItems,
        OrderTotal: this.totalPrice,
        SpecialOrder: this.placeSpecialOrder,
      };
      //upload document
      if (this.document) {
        if (!this.checkFileRule(this.document)) {
          this.errorMessage =
            "Make sure you Upload a file that conforms with pdf, word document";
          this.showFailureAlert();
          return;
        }
        const formData = new FormData();
        formData.append(`document`, this.document);
        uploadresponse = await FileService.uploadFile(formData);
        order.DiscountProofURL = uploadresponse ? uploadresponse.data.Path : "";
      }
      if (this.discountAllowed) {
        order.DiscountReason = this.DiscountReason;
      }

      console.log("order to place is:", order);

      this.orderWithDiscount = this.discountAllowed;

      /**/
      var response = await VehicleService.placeOrder(order);
      this.orderid = response.data.OrderNumber;
      this.expiry = VehicleService.convertDate(response.data.ExpiryDate);

      this.ordering = false;
      this.confirmModal = false;
      this.successModal = true;
      this.DiscountReason = "";
      this.discountAllowed = false;
      this.cart = [];
    },
    validateDiscount() {
      if (this.discountAllowed) {
        if (
          this.cart.filter(function (e) {
            return !(e.DiscountRequested >= 0);
          }).length > 0
        ) {
          return false;
        }
      }
      return true;
    },
    filteredCars() {
      if (!this.filterText) {
        return this.allVehcilesNoFilter;
      } else {
        let cars = this.allVehcilesNoFilter.filter((car) => {
          return (
            car.Description.toLowerCase().indexOf(
              this.filterText.toLowerCase()
            ) > -1
          );
        });

        return cars;
      }
    },
    checkFileRule(file) {
      return /\.(pdf|doc|docx|PDF|DOC|DOCX)$/.test(file.name);
    },
    showFailureAlert() {
      this.dismissCountDownFail = this.dismissSecs;
      this.ordering = false;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    countDownChangedFail(dismissCountDown) {
      this.dismissCountDownFail = dismissCountDown;
    },
    determineCategory(dealerid) {
      return config.categoryADealers.filter((x) => x == dealerid).length > 0
        ? "A"
        : "B";
    },
    //placeOrder() {}
  },
  watch: {
    filterText: function (val) {
      if (val.length > 2) {
        this.allVehciles = this.filteredCars();
      } else {
        this.allVehciles = this.allVehcilesNoFilter;
      }
      //this.fullName = val + ' ' + this.lastName
    },
    discountAllowed: function (discount) {
      if (discount) {
        this.cart.map((item) => {
          return (item.DiscountRequested = 0);
        });
      }
    },
  },
};
</script>
<style scoped>
.mt-6 {
  margin-top: 4rem !important;
}

.alert {
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
}

.mt-6 {
  margin-top: 4rem !important;
}

.alert {
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
}
